<template>
  <LayuotPage>
    <div class="container-sm ps-7">
      <div class="card-body">
        <div class="row">
          <!-- Grid column -->
          <div class="col-md-12">
            <h2 class="pt-3 pb-4 text-left font-bold font-up deep-purple-text">
              Группы выкупов
            </h2>
            <MyBreadCrumbs :crumbs="crumbs"></MyBreadCrumbs>
          </div>
          <div class="col-4">
            <MyButton @click="$router.push('/repayment/create')"
              >Добавить выкуп</MyButton
            >
          </div>
          <!-- Sort Block -->
          <div class="row d-flex justify-content-between mb-2">
            <div class="col-12">
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{ active: currentState === 'all' }"
                    href="#"
                    @click="sortByStatus('all')"
                  >
                    Все выкупы</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{ active: currentState === 'active' }"
                    href="#"
                    @click="sortByStatus('active')"
                  >
                    Неоплаченные</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{ active: currentState === 'done' }"
                    href="#"
                    @click="sortByStatus('done')"
                  >
                    Завершенные</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    :class="{ active: currentState === 'archive' }"
                    href="#"
                    @click="sortByStatus('archive')"
                  >
                    В архиве</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row" v-if="!isLoading">
          <template v-if="sortedOrders.length > 0">
            <OrderCart
              v-for="order in sortedOrders"
              :key="order.id"
              :order="order"
              @action="ActionOrder"
            ></OrderCart>
          </template>
        </div>
        <div class="row" v-else>
          <CardSkeleton v-for="index in 5" :key="index"></CardSkeleton>
        </div>
      </div>
    </div>
  </LayuotPage>
</template>
<script setup lang="ts">
import { ref, watch } from "vue";
import LayuotPage from "../LayuotPage.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import OrderCart from "./components/OrderCart.vue";
import CardSkeleton from "@/components/skeletons/CardSkeleton.vue";
import RepaymentService from "./hooks/RepaymentService";
import { IRepaymentOrder } from "./intefaces";

// Import Swiper styles
import "swiper/css";

const crumbs = [
  {
    text: "Группы выкупов",
    url: "/repayment",
  },
  {
    text: "Выкупы",
  },
];

const { isLoading, repaymentOrders } = RepaymentService.getUserRepayments();
const sortedOrders = ref<IRepaymentOrder[]>([]);
const currentState = ref("");
const sortByStatus = (srt: string) => {
  currentState.value = srt;
  if (srt === "all") {
    sortedOrders.value = repaymentOrders.value;
  } else if (srt == "archive") {
    sortedOrders.value = repaymentOrders.value.filter(
      (order: IRepaymentOrder) => order.is_archive === true
    );
  } else {
    sortedOrders.value = repaymentOrders.value.filter(
      (order: IRepaymentOrder) => order.status === srt.toUpperCase()
    );
  }
};
const ActionOrder = (id: number, action: string) => {
  RepaymentService.archiveRepayment(id, action);
};
watch(repaymentOrders, () => {
  sortByStatus("all"); // или любой другой статус, который вы хотите использовать по умолчанию
});
</script>
<style scoped>
@media (max-width: 768px) {
  .card {
    width: 100%; /* На мобильных устройствах карточки будут занимать всю ширину */
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
