import { onMounted, ref } from "vue";
import axios from "@/axiosInstance";
import { IPaymentHistory, ITarif } from "../interfaces";

class PaymentService {
  getTarifs() {
    const tarifs = ref(Array<ITarif>());
    const isLoading = ref(true);
    const fetching = async () => {
      try {
        const response = await axios.get("payment/get/services");
        tarifs.value = response.data;
      } catch (e) {
        alert("Error");
      } finally {
        isLoading.value = false;
      }
    };
    onMounted(fetching);
    return { isLoading, tarifs };
  }

  async loadMoreTransactions(page: number, pageSize: number) {
    const data = ref<Array<IPaymentHistory>>([]);
    try {
      const response = await axios.get("payment/get/history", {
        params: { page, size: pageSize },
      });
      data.value = response.data;
      return data.value;
    } catch (e) {
      alert("Ошибка при загрузке данных");
      throw e;
    }
  }
}

export default new PaymentService();
