<template>
  <LayuotPage>
    <div class="container-sm main ps-7">
      <div class="card-body">
        <div class="row">
          <!-- Grid column -->
          <div class="col-md-12">
            <h2 class="pt-3 pb-4 text-left font-bold font-up deep-purple-text">
              История финансов
            </h2>
            <MyBreadCrumbs :crumbs="crumbs"></MyBreadCrumbs>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <table class="table table-striped">
              <!--Table head-->
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Сумма</th>
                  <th>Описание</th>
                  <th>Тип</th>
                  <!-- <th>Статус</th> -->
                  <th>Время</th>
                </tr>
              </thead>
              <!--Table head-->
              <!--Table body-->
              <tbody>
                <tr v-for="(transaction, index) in items" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    {{ transaction.transaction.amount }}
                  </td>
                  <td>{{ transaction.transaction.description }}</td>
                  <td>
                    <StatusSpan
                      :color-class="
                        statusClassStatus(
                          transaction.transaction.transaction_type
                        ).class
                      "
                      >{{
                        statusClassStatus(
                          transaction.transaction.transaction_type
                        ).text
                      }}</StatusSpan
                    >
                  </td>
                  <!-- <td>
                  <template v-if="transaction.type"> Завершено </template>
                  <template v-else> В процессе </template>
                </td> -->
                  <td>{{ transaction.history_datetime }}</td>
                </tr>
              </tbody>
              <template v-if="items.length == 0">
                <RowSkeleton
                  v-for="index in 10"
                  :key="index"
                  :columns="5"
                ></RowSkeleton>
              </template>
              <!--Table body-->
            </table>
            <div ref="loadMoreTrigger" class="load-more-trigger"></div>
          </div>
        </div>
      </div>
    </div>
  </LayuotPage>
</template>
<script setup lang="ts">
import LayuotPage from "../LayuotPage.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import PaymentService from "./hooks/PaymentService";
import StatusSpan from "@/components/UI/StatusSpan.vue";
import { statusClassStatus } from "@/hooks/";
import RowSkeleton from "@/components/skeletons/RowSkeleton.vue";
import { useIntersectionObserver } from "@/hooks/useIntersectionObserver";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
const crumbs = [
  {
    text: "История финансов",
    url: "/history",
  },
  {
    text: "Финансы",
  },
];
const fetchData = (page: number, pageSize: number) =>
  PaymentService.loadMoreTransactions(page, pageSize);

// Используем useInfiniteScroll с fetchData
const { items, loadMore } = useInfiniteScroll(fetchData);
// Подключаем IntersectionObserver, передавая loadMore как callback
const { target: loadMoreTrigger } = useIntersectionObserver(loadMore, {
  rootMargin: "100px",
});
</script>

<style scoped>
.load-more-trigger {
  height: 1px;
}
</style>
