<template>
  <div class="input-group number-input">
    <button
      class="btn-sm btn btn-outline-secondary"
      type="button"
      @click="decrement"
    >
      -
    </button>
    <input
      type="number"
      class="form-control text-center"
      :step="step"
      :min="1"
      :max="max || 100"
      v-model="localValue"
    />
    <button
      class="btn-sm btn btn-outline-secondary"
      type="button"
      @click="increment"
    >
      +
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, defineEmits, defineProps, watch } from "vue";

const props = defineProps<{
  modelValue: number;
  step: number;
  max?: number;
}>();

const emit = defineEmits(["update:modelValue"]);

const maxLimit = computed(() => props.max ?? 100);
const localValue = ref(props.modelValue);

// Следим за изменениями и ограничиваем значение в пределах
watch(localValue, (newValue) => {
  if (newValue < 1) {
    localValue.value = 1;
  } else if (newValue > maxLimit.value) {
    localValue.value = maxLimit.value;
  }
  emit("update:modelValue", localValue.value);
});

function increment() {
  if (localValue.value + props.step <= maxLimit.value) {
    localValue.value += props.step;
  }
}

function decrement() {
  if (localValue.value - props.step >= 1) {
    localValue.value -= props.step;
  }
}
</script>

<style scoped>
.number-input,
input {
  font-size: small !important;
}
/* Убираем кнопки для всех браузеров */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Для Firefox */
}
</style>
