<template>
  <menu-panel></menu-panel>
  <router-view />
  <notifications position="bottom right" classes="vue-notification-my" />
</template>
<script setup>
import MenuPanel from "./components/MenuPanel.vue";
import { useStore } from "vuex";
const store = useStore();
store.dispatch("points/setCurrentPoint");
</script>
<style>
body {
  padding: 0;
  margin: 0;
}

body.dark {
  --body-color: #18191a;
  --sidebar-color: #242526;
  --primary-color: #3a3b3c;
  --primary-color-light: #3a3b3c;
  --toggle-color: #fff;
  --text-color: #ccc;
}
@media (max-width: 767.98px) {
  h2 {
    text-align: center;
  }
}
</style>
<style lang="scss">
@import "@/styles/custom.scss";
</style>
