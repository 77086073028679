import { Module } from "vuex";
import axios from "@/axiosInstance";
import { openDatabase, savePunkts, getPunkts } from "../indexDB";
import { IState as RootState } from "../index";
import { YMapMarkerProps } from "@yandex/ymaps3-types/imperative/YMapMarker";
import { IPoint } from "@/views/repayment/intefaces";
import { ref } from "vue";

export interface PointsState {
  markers: YMapMarkerProps[];
  isLoading: boolean;
  currentState: YMapMarkerProps | undefined;
}

export function convertPointsToMarkers(pvzs: IPoint[]): YMapMarkerProps[] {
  return pvzs.map((elem: IPoint) => {
    return {
      coordinates: [elem.lon, elem.lat],
      properties: {
        dsc: elem.address,
        id: Number(elem.id),
      },
    };
  });
}

const state: PointsState = {
  markers: [],
  isLoading: true,
  currentState: undefined,
};

const pointsModule: Module<PointsState, RootState> = {
  namespaced: true,
  state,
  mutations: {
    setMarkers(state, markers: YMapMarkerProps[]) {
      state.markers = markers;
    },
    setLoading(state, isLoading: boolean) {
      state.isLoading = isLoading;
    },
    setCurrentState(state, currentState: YMapMarkerProps) {
      state.currentState = currentState;
    },
  },
  actions: {
    async fetchMarkers({ commit }) {
      const db = await openDatabase();
      const localPunkts = await getPunkts(db);
      const markers = ref(Array<YMapMarkerProps>());
      const pvzs = ref(Array<IPoint>());

      // try {
      const resp = await axios.get("repayment/get/punkts/count");
      const count = resp.data.count;
      if (count !== localPunkts.length) {
        const response = await axios.get(`repayment/get/punkts`);
        pvzs.value = response.data.punkts;
        await savePunkts(db, pvzs.value);
      } else {
        pvzs.value = localPunkts;
      }
      markers.value = convertPointsToMarkers(pvzs.value);
      commit("setMarkers", markers.value);
      // } catch (e) {
      //   alert("Error");
      // } finally {
      commit("setLoading", false);
    },
    async setCurrentPoint({ commit }) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          commit("setCurrentState", {
            coordinates: [longitude, latitude],
          });
        });
      } else {
        commit("setCurrentState", {
          coordinates: [55.755819, 37.617644],
        });
      }
    },
  },
};

export default pointsModule;
