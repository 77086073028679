import axios from "@/axiosInstance";
import { ref } from "vue";
import { ILikeOrder } from "../interfaces"; // Убедитесь, что путь до интерфейсов правильный
import store from "@/store";

class LikeService {
  async getLikes(page: number, pageSize: number) {
    const likes = ref<Array<ILikeOrder>>([]);
    try {
      const response = await axios.get(`warming/get/likes`, {
        params: { page, size: pageSize },
      });
      likes.value = response.data;
      return likes.value;
    } catch (e) {
      alert("Error");
    }
  }

  async createLikes(likeForm: any) {
    try {
      const response = await axios.post("warming/post/create", likeForm.value);
      if (response.status == 200) {
        store.dispatch("refreshBalance");
      }
      return response.status == 200;
    } catch (e) {
      return false;
    }
  }
}

export default new LikeService();
