<template>
  <LayuotPage>
    <div class="container-sm ps-7">
      <div class="card-body">
        <div class="row">
          <!-- Grid column -->
          <div class="col-md-12">
            <h2 class="pt-3 pb-4 text-left font-bold font-up deep-purple-text">
              Корзина
            </h2>
            <MyBreadCrumbs :crumbs="crumbs"></MyBreadCrumbs>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label for="article" class="form-label">Артикул</label>
              <TextInput
                v-model="newCart.article"
                :id="'article'"
                :placeholder="'Артикул'"
                @input="GetProductInfo"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="query" class="form-label">Ключевой запрос</label>
              <TextInput
                v-model="newCart.query"
                :id="'query'"
                :placeholder="'Ключевой запрос'"
                :disabled="!currentProduct"
                @input="if (currentProduct) handleInput(currentProduct);"
              />
              <ToolTip
                v-if="currentProduct"
                :product="currentProduct"
                :show="showTooltip"
                @close-tip="closeToolTip"
              >
                <template
                  v-if="currentProduct.index == -1 && currentProduct.page == -1"
                >
                  <div>
                    <i class="fas fa-check-circle text-success"></i>
                    Товар по указанному запросу не найден. Попробуйте другой
                    запрос.
                    <br />
                  </div>
                </template>
                <template
                  v-else-if="
                    currentProduct.index != undefined &&
                    currentProduct.page != undefined
                  "
                >
                  <i class="fas fa-check-circle text-success"></i>
                  Поиск будет осуществлен по ключу "{{ currentProduct.query }}".
                  Товар находится на {{ currentProduct.page }} странице, позиция
                  {{ currentProduct.index }}.
                  <br />
                  <button
                    class="btn text-light bg-secondary"
                    @click="confirmQuery(currentProduct)"
                  >
                    Применить
                  </button>
                </template>
                <template v-else>
                  <div class="d-flex justify-content-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Загрузка...</span>
                    </div>
                  </div>
                </template>
              </ToolTip>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3">
              <label class="form-label">Размер</label>
              <div
                v-if="
                  currentProduct && Object.keys(currentProduct.sizes).length > 0
                "
              >
                <MySelect
                  :options="currentProduct.sizes"
                  v-model="newCart.size"
                />
              </div>
              <div v-else>
                <MySelect :options="[]" v-model="newCart.size" />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row g-3">
              <div class="col-md-4">
                <div class="mb-3">
                  <label for="count" class="form-label">Количество</label>
                  <NumberInput
                    v-model="newCart.count"
                    :step="10"
                    :max="100"
                  ></NumberInput>
                </div>
              </div>
              <div class="col-md-4">
                <div class="mb-3">
                  <label class="form-label">Период выполнения</label>
                  <MySelect :options="hours" v-model="newCart.hours"></MySelect>
                </div>
              </div>
              <!-- Добавлен пустой блок для выравнивания элементов по высоте -->
              <div class="col-md-4">
                <div class="mb-3">
                  Стоимость:<br />
                  {{ service?.cost }} Р/шт
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <MyButton
              type="submit"
              class="btn btn-primary"
              @click="SendCart"
              :disabled="createLoading"
            >
              <span
                v-if="createLoading"
                class="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              {{ createLoading ? "Отправка..." : "Добавить" }}
            </MyButton>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <table class="table table-striped">
              <!--Table head-->
              <thead>
                <tr>
                  <th>#</th>
                  <th>Картинка</th>
                  <th>Ссылка на товар</th>
                  <th>Размер</th>
                  <th>Прогресс</th>
                  <th>Стоимость</th>
                  <th>Статус</th>
                  <th>Дата создания</th>
                  <th>Дата окончания</th>
                  <th></th>
                </tr>
              </thead>
              <!--Table head-->
              <!--Table body-->
              <tbody>
                <tr v-for="(cart, index) in items" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <div class="col-auto">
                      <ImageContainer
                        :photo_url="cart.product.photo_url"
                      ></ImageContainer>
                    </div>
                    <!-- <img
                    :src="cart.product.photo_url"
                    :alt="cart.product.photo_url"
                    width="50"
                    height="50"
                  /> -->
                  </td>
                  <td>{{ cart.product.article }}</td>
                  <td>{{ cart.size }}</td>
                  <td>{{ cart.done }}/{{ cart.total }}</td>
                  <td v-if="service">{{ service.cost * cart.total }} руб.</td>
                  <td>
                    <template v-if="cart.done === cart.total">
                      <StatusSpan
                        :color-class="statusClassStatus('DONE').class"
                        >{{ statusClassStatus("DONE").text }}</StatusSpan
                      >
                    </template>
                    <template v-else
                      ><StatusSpan
                        :color-class="statusClassStatus('PROCCESS').class"
                        >{{ statusClassStatus("PROCCESS").text }}</StatusSpan
                      ></template
                    >
                  </td>
                  <td>{{ cart.date_start }}</td>
                  <td>{{ cart.date_end }}</td>
                </tr>
              </tbody>
              <template v-if="items.length === 0">
                <RowSkeleton
                  v-for="index in 5"
                  :key="index"
                  :columns="9"
                ></RowSkeleton>
              </template>
              <!--Table body-->
            </table>
            <div ref="loadMoreTrigger" class="load-more-trigger"></div>
          </div>
        </div>
      </div>
    </div>
  </LayuotPage>
</template>
<script setup lang="ts">
import { onMounted, onUnmounted, ref } from "vue";
import LayuotPage from "../LayuotPage.vue";
import MySelect from "@/components/UI/MySelect.vue";
import MyButton from "@/components/UI/MyButton.vue";
import NumberInput from "@/components/UI/NumberInput.vue";
import TextInput from "@/components/UI/TextInput.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import ImageContainer from "@/components/UI/ImageContainer.vue";
import RowSkeleton from "@/components/skeletons/RowSkeleton.vue";
import { IRepaymentProduct } from "../repayment/intefaces";
import CartWarmService from "./hooks/CartWarmService";
import WBService from "@/hooks/WBService";
import ToolTip from "@/components/ToolTip.vue";
import { useNotification } from "@kyvg/vue3-notification";
import { IService } from "@/hooks/interfaces";
import { getService, statusClassStatus } from "@/hooks";
import StatusSpan from "@/components/UI/StatusSpan.vue";
import { useStore } from "vuex";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import { useIntersectionObserver } from "@/hooks/useIntersectionObserver";
const { notify } = useNotification();
const crumbs = [
  {
    text: "",
    url: "/cart",
  },
  {
    text: "Корзина",
  },
];
const hours = [
  { value: "3", name: "3 часа" },
  { value: "12", name: "12 часов" },
  { value: "24", name: "1 день" },
  { value: "72", name: "3 дня" },
  { value: "168", name: "7 дней" },
  { value: "336", name: "14 дней" },
];
const errors = ref({
  article: true,
});
const store = useStore();
store.dispatch("points/setCurrentPoint");
const currentProduct = ref<IRepaymentProduct>();
const GetProductInfo = async () => {
  const regex = /^[\d]{5,9}$/;
  errors.value.article = regex.test(newCart.value.article);
  if (newCart.value.article) {
    newCart.value.query = "";
    showTooltip.value = false;
    currentProduct.value = await WBService.getProduct(newCart.value.article);
  }
};
const newCart = ref({
  article: "",
  query: "",
  size: "",
  count: 1,
  hours: "3",
});
const showTooltip = ref(false);
const closeToolTip = () => {
  showTooltip.value = false;
};
let debounceTimeout: ReturnType<typeof setTimeout>;
const SearchProductPosition = async (
  product: IRepaymentProduct,
  retries = 10
) => {
  product.query_confirm = false;
  const token = await WBService.SearchProductTask(
    product.article,
    product.query
  );
  if (!token) {
    // Если не удалось получить токен, завершаем выполнение
    return;
  }

  let result;
  while (retries > 0 && active) {
    try {
      result = await WBService.getTaskResult(token);
      if (result) {
        showTooltip.value = true;
        product.index = result.index;
        product.page = result.page;
        return;
      } else {
        // Если результат еще не готов и есть попытки, ожидаем 2 секунды перед повторным запросом
        await new Promise((resolve) => setTimeout(resolve, 10000));
        retries--;
      }
    } catch (error: any) {
      if (error.name === "AbortError") {
        console.log("Request was aborted");
        return;
      }
      console.error("Error fetching task result:", error);
    }
  }
  if (retries == 0 && !result) {
    product.index = 0;
    product.page = 0;
  }
};
let active = true;
const handleInput = (product: IRepaymentProduct) => {
  // Если уже существует таймер, сбросить его
  clearTimeout(debounceTimeout);
  if (product.id) {
    showTooltip.value = true;
    product.page = undefined;
    product.index = undefined;
    product.query = newCart.value.query;
    // Установить новый таймер
    debounceTimeout = setTimeout(async () => {
      await SearchProductPosition(product);
    }, 500); // Задержка в 500 мс
  }
};
const confirmQuery = (product: IRepaymentProduct) => {
  product.query_confirm = true;
  newCart.value.query = product.query;
  showTooltip.value = false;
};
onUnmounted(() => {
  active = false;
  clearTimeout(debounceTimeout);
});

const SendCart = async () => {
  console.log(newCart.value);
  let succes = true;
  if (!newCart.value.article) {
    notify({
      title: "Предупреждение",
      text: "Введите корректный артикул",
    });
    succes = false;
  } else if (!newCart.value.query) {
    notify({
      title: "Предупреждение",
      text: "Введите поисковый запрос",
    });
    succes = false;
  }
  if (succes) {
    createLoading.value = true;
    await CartWarmService.createCarts(newCart);
    unobserve();
    await refresh();
    observe();
    createLoading.value = false;
    newCart.value = {
      article: "",
      query: "",
      size: "",
      count: 1,
      hours: "3",
    };
  }
};
const createLoading = ref(false);
const service = ref<IService>();
const fetchData = (page: number, pageSize: number) =>
  CartWarmService.getCarts(page, pageSize);

// Используем useInfiniteScroll с fetchData
const { items, loadMore, refresh } = useInfiniteScroll(fetchData);
// Подключаем IntersectionObserver, передавая loadMore как callback
const {
  target: loadMoreTrigger,
  observe,
  unobserve,
} = useIntersectionObserver(loadMore, { rootMargin: "100px" });
onMounted(async () => {
  service.value = (await getService("cartwarm")).value;
});
</script>
