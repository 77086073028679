<template>
  <div v-if="thumbnailUrl" class="image-container">
    <div
      class="blur-background"
      :style="{ backgroundImage: 'url(' + thumbnailUrl + ')' }"
    ></div>
    <img
      :src="thumbnailUrl"
      class="img-fluid br-5 product-image"
      alt="Media Thumbnail"
    />
    <!-- Отображаем значок, если это видео -->
    <div v-if="isVideo" class="video-icon-overlay">▶️</div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, onMounted } from "vue";
import axios from "axios";

const props = defineProps<{ photo_url: string }>();

const thumbnailUrl = ref<string>(""); // URL изображения или кадра видео
const isVideo = ref<boolean>(false); // Флаг для обозначения, является ли файл видео

// Функция для извлечения кадра из середины видео
async function generateVideoThumbnail(videoUrl: string): Promise<string> {
  return new Promise((resolve) => {
    const video = document.createElement("video");
    video.src = videoUrl;
    video.muted = true;
    video.playsInline = true;

    const canvas = document.createElement("canvas");

    video.addEventListener("loadedmetadata", () => {
      video.currentTime = video.duration / 2; // Перематываем на середину видео
    });

    video.addEventListener("seeked", () => {
      // Устанавливаем размеры canvas
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const context = canvas.getContext("2d");
      if (context) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
      }
      resolve(canvas.toDataURL("image/jpeg")); // Возвращаем URL изображения
    });
  });
}

onMounted(async () => {
  try {
    const response = await axios.get(props.photo_url, {
      responseType: "blob", // Загружаем данные в виде Blob
    });

    const mediaType = response.headers["content-type"];
    const mediaBlobUrl = URL.createObjectURL(response.data);

    // Если это видео, создаем миниатюру из середины и устанавливаем флаг `isVideo`
    if (mediaType.startsWith("video/")) {
      isVideo.value = true;
      thumbnailUrl.value = await generateVideoThumbnail(mediaBlobUrl);
    } else {
      // Если это изображение, используем его URL напрямую
      thumbnailUrl.value = mediaBlobUrl;
    }
  } catch (error) {
    console.error("Ошибка при загрузке медиафайла:", error);
  }
});
</script>

<style scoped>
.image-container {
  position: relative;
  width: 100%;
  padding-top: 100%; /* Пропорция 1:1 для квадрата */
  overflow: hidden;
  border-radius: 8px;
}

.blur-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  filter: blur(8px);
  z-index: 0;
}

.product-image {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 100%;
  max-width: 100%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.video-icon-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: rgba(255, 255, 255, 0.8);
  pointer-events: none;
  z-index: 2;
}
@media (max-width: 768px) {
  .video-icon-overlay {
    font-size: 2rem; /* Уменьшенный размер иконки на мобильных */
  }
}
</style>
