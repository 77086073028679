import axios from "@/axiosInstance";
import { ref } from "vue";
import { IReviewLikeOrder } from "../interfaces"; // Убедитесь, что путь до интерфейсов правильный
import store from "@/store";
import { Feedback } from "@/hooks/interfaces";

class ReviewLikeService {
  async createReviewLikes(article: string, reviews: Array<Feedback>) {
    const data = {
      article: article,
      reviews: reviews,
    };
    try {
      const response = await axios.post("warming/post/votes/create", data);
      if (response.status == 200) {
        store.dispatch("refreshBalance");
      }
      return response.status == 200;
    } catch (e) {
      return false;
    }
  }

  async getReviewLikes(page: number, pageSize: number) {
    // onMounted(this.fetching);
    const isLoading = ref(true);
    const orders = ref<Array<IReviewLikeOrder>>([]);
    isLoading.value = true;
    try {
      const response = await axios.get(`warming/get/votes`, {
        params: { page, size: pageSize },
      });
      orders.value = response.data;
      return orders.value;
    } catch (e) {
      alert("Error");
    } finally {
      isLoading.value = false;
    }
  }
}

export default new ReviewLikeService();
