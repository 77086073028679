<template>
  <div class="container mt-2 mb-4">
    <div class="col-sm-8 mx-auto">
      <ul class="nav nav-pills nav-fill mb-1" id="pills-tab" role="tablist">
        <!-- <li class="nav-item">
          <MyButton
            class="nav-link"
            :class="{ active: !Sign }"
            @click="ChangeForm()"
            id="pills-signin-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-signin"
            type="button"
            role="tab"
            aria-controls="pills-signin"
            aria-selected="true"
          >
            Войти
          </MyButton>
        </li> -->
        <!-- <li class="nav-item">
          <MyButton
            class="nav-link"
            :class="{ active: Sign }"
            @click="ChangeForm()"
            id="pills-signup-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-signup"
            type="button"
            role="tab"
            aria-controls="pills-signup"
            aria-selected="false"
          >
            Зарегистрироваться
          </MyButton>
        </li> -->
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade"
          :class="{ active: !Sign, show: !Sign }"
          id="pills-signin"
          role="tabpanel"
          aria-labelledby="pills-signin-tab"
        >
          <div class="col-sm-12 border border-primary shadow rounded p-3">
            <div class="text-center">
              <img :src="logo" class="rounded-circle border p-1" width="100" />
            </div>
            <SigninForm
              :userLogin="userLogin"
              @submit="Login"
              @click:open-modal="() => open()"
            ></SigninForm>
          </div>
        </div>
        <!-- <div
          class="tab-pane fade"
          :class="{ active: Sign, show: Sign }"
          id="pills-signup"
          role="tabpanel"
          aria-labelledby="pills-signup-tab"
        >
          <div class="col-sm-12 border border-primary shadow rounded p-3">
            <div class="text-center">
              <img
                src="https://placehold.it/80x80"
                class="rounded-circle border p-1"
              />
            </div>
            <SignUpForm
              :userRegistration="userRegistration"
              @submit="Registration"
            ></SignUpForm>
          </div>
        </div> -->
      </div>
    </div>

    <!-- Modal -->
    <ModalsContainer />
  </div>
</template>
<script setup lang="ts">
import { useStore } from "vuex";
import { reactive, ref } from "vue";
import ForgotModal from "./modals/ForgotModal.vue";
import SigninForm from "@/views/auth/components/SigninForm.vue";
import SignUpForm from "@/views/auth/components/SignUpForm.vue";
import { IUserLogin, IUserRegistration } from "@/views/auth/interfaces";
import AuthService from "./hooks/AuthService";
import { useRouter } from "vue-router";
import { ModalsContainer, useModal } from "vue-final-modal";
import { useNotification } from "@kyvg/vue3-notification";
const { notify } = useNotification();
const logo = new URL("@/assets/images/logo.png", import.meta.url).href;
const store = useStore();
const router = useRouter();
const userLogin = reactive<IUserLogin>({
  username: "",
  password: "",
});

// const userRegistration = reactive<IUserRegistration>({
//   email: "",
//   phone: "",
//   name: "",
//   password: "",
//   password2: "",
// });
// const currUser = computed(() => store.getters.getUser);
const Sign = ref(false);
const ChangeForm = () => {
  Sign.value = !Sign.value;
};
const Login = async () => {
  try {
    await store.dispatch("login", {
      email: userLogin.username,
      password: userLogin.password,
    });
    // Обработка успешного входа, возможно перенаправление
    console.log("Login successful");
    router.push("/repayment");
  } catch (error) {
    // Обработка ошибок входа
    notify({
      text: "Неправильный логин или пароль",
    });
    console.error("Login failed", error);
  }
  // console.log(store.state.user);
};
// const Registration = async () => {
//   // console.log(userRegistration);
//   const response = await axios.post("token/signup", userRegistration);
//   if (response.status == 201 || response.status == 200) {
//     Sign.value = false;
//     notify({
//       title: "Уведомление",
//       text: "Вы успешно зарегистрировались",
//     });
//   }
// };
const ForgotEmail = async (email: string) => {
  const status = await AuthService.forgotPassword(email);
  // console.log(error.value, message.value);
  if (status == true) {
    notify({
      text: "Проверьте указанную почту. Вам придет ссылка на изменение пароля",
    });
    close();
  }
};
const { open, close } = useModal({
  component: ForgotModal,
  attrs: {
    onCloseicon() {
      close();
    },
    onUpdate(email: string) {
      ForgotEmail(email);
    },
  },
});
</script>
