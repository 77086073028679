import axios from "@/axiosInstance";
import { ref } from "vue";
import { ICartWarmProduct } from "../interfaces"; // Убедитесь, что путь до интерфейсов правильный
import store from "@/store";

class CartWarmService {
  async createCarts(newCart: any) {
    try {
      const response = await axios.post(
        "warming/post/cartwarm/create",
        newCart.value
      );
      if (response.status == 200) {
        store.dispatch("refreshBalance");
      }
      return response.status == 200;
    } catch (e) {
      return false;
    }
  }

  async getCarts(page: number, pageSize: number) {
    // onMounted(this.fetching);
    const carts = ref<Array<ICartWarmProduct>>([]);
    try {
      const response = await axios.get(`warming/get/cartwarms`, {
        params: { page, size: pageSize },
      });
      carts.value = response.data;
      return carts.value;
    } catch (e) {
      alert("Error");
    } // При первом вызове getLikes он автоматически загружает данные
  }
}

export default new CartWarmService();
