// hooks/useIntersectionObserver.ts
import { ref, onMounted, onBeforeUnmount, Ref } from "vue";

export function useIntersectionObserver(callback: () => void, options = {}) {
  const target = ref<HTMLElement | null>(null);
  let observer: IntersectionObserver | null = null;

  // Функция для наблюдения за элементом
  const observe = () => {
    if (target.value && observer) {
      observer.observe(target.value);
    }
  };

  // Функция для прекращения наблюдения за элементом
  const unobserve = () => {
    if (target.value && observer) {
      observer.unobserve(target.value);
    }
  };

  onMounted(() => {
    observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        callback();
      }
    }, options);

    observe(); // Начинаем наблюдение при монтировании
  });

  onBeforeUnmount(() => {
    unobserve();
    observer?.disconnect();
  });

  return { target, observe, unobserve };
}
