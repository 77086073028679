<template>
  <LayuotPage>
    <div class="container-sm ps-7">
      <div class="card-body">
        <div class="row">
          <!-- Grid column -->
          <div class="col-md-12">
            <h2 class="pt-3 pb-4 text-left font-bold font-up deep-purple-text">
              Вопросы на товар
            </h2>
            <MyBreadCrumbs :crumbs="crumbs"></MyBreadCrumbs>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 mb-3">
            <label for="article" class="form-label">Артикул</label>
            <TextInput
              v-model="newQuestion.article"
              :id="'article'"
              :placeholder="'Артикул'"
            />
          </div>

          <div class="col-md-4 mb-3">
            <label for="publish_date" class="form-label"
              >Дата и время публикации вопроса</label
            >
            <div class="input-group">
              <input
                type="datetime-local"
                class="form-control"
                id="publish_date"
                v-model="newQuestion.publish_date"
                :min="minDate"
                :max="maxDate"
              />
            </div>
          </div>

          <div class="col-md-4 mb-3">
            <label for="gender" class="form-label">Пол</label>
            <div class="input-group">
              <MySelect
                id="gender"
                v-model="newQuestion.gender"
                :options="gender"
                disable
              ></MySelect>
            </div>
          </div>

          <div class="col-md-9 mb-3">
            <label for="question" class="form-label">Ваш вопрос</label>
            <div class="input-group">
              <TextInput
                v-model="newQuestion.question"
                :id="'question'"
                :placeholder="'Ваш вопрос'"
              />
            </div>
          </div>

          <div class="col-md-3 mb-3">
            <div class="mb-3">
              Стоимость:<br />
              {{ service?.cost }} Р/шт
            </div>
          </div>

          <div class="col-md-12 mb-3">
            <div class="input-group">
              <MyButton
                type="submit"
                class="btn btn-primary"
                @click="SendQuestion"
                :disabled="createLoading"
              >
                <span
                  v-if="createLoading"
                  class="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ createLoading ? "Отправка..." : "Добавить" }}
              </MyButton>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <table class="table table-striped">
              <!--Table head-->
              <thead>
                <tr>
                  <th>#</th>
                  <th>Ссылка на товар</th>
                  <th>Вопрос</th>
                  <th>Пол</th>
                  <th>Стоимость</th>
                  <th>Статус</th>
                  <th>Дата создания</th>
                  <th>Дата окончания</th>
                  <th></th>
                </tr>
              </thead>
              <!--Table head-->
              <!--Table body-->
              <tbody>
                <tr v-for="(question, index) in items" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <div class="col-7">
                      <a
                        :href="`https://www.wildberries.ru/catalog/${question.product.article}/detail.aspx`"
                      >
                        <ImageContainer
                          :photo_url="question.product.photo_url"
                        ></ImageContainer>
                      </a>
                    </div>
                    <!-- <img
                    :src="question.product.photo_url"
                    :alt="question.product.photo_url"
                    width="50"
                    height="50"
                  /> -->
                  </td>
                  <td>{{ question.question }}</td>
                  <td>{{ question.gender }}</td>
                  <td v-if="service">{{ service.cost }} руб.</td>
                  <td>
                    <template v-if="question.status">
                      <StatusSpan
                        :color-class="statusClassStatus('DONE').class"
                        >{{ statusClassStatus("DONE").text }}</StatusSpan
                      >
                    </template>
                    <template v-else
                      ><StatusSpan
                        :color-class="statusClassStatus('PROCCESS').class"
                        >{{ statusClassStatus("PROCCESS").text }}</StatusSpan
                      ></template
                    >
                  </td>
                  <td>{{ question.created }}</td>
                  <td>{{ question.publish_date }}</td>
                </tr>
              </tbody>
              <template v-if="items.length === 0">
                <RowSkeleton
                  v-for="index in 5"
                  :key="index"
                  :columns="9"
                ></RowSkeleton>
              </template>
              <!--Table body-->
            </table>
            <div ref="loadMoreTrigger" class="load-more-trigger"></div>
          </div>
        </div>
      </div>
    </div>
  </LayuotPage>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import LayuotPage from "../LayuotPage.vue";
import MyButton from "@/components/UI/MyButton.vue";
import MySelect from "@/components/UI/MySelect.vue";
import TextInput from "@/components/UI/TextInput.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import ImageContainer from "@/components/UI/ImageContainer.vue";
import RowSkeleton from "@/components/skeletons/RowSkeleton.vue";
import QuestionService from "./hooks/QuestionService";
import { IService } from "@/hooks/interfaces";
import { getService, getFormattedDate, statusClassStatus } from "@/hooks";
import StatusSpan from "@/components/UI/StatusSpan.vue";
import { notify } from "@kyvg/vue3-notification";
import { useInfiniteScroll } from "@/hooks/useInfiniteScroll";
import { useIntersectionObserver } from "@/hooks/useIntersectionObserver";
const crumbs = [
  {
    text: "Вопросы на товар",
    url: "/questions",
  },
  {
    text: "Вопросы",
  },
];
const gender = [
  { name: "Муж.", value: "male" },
  { name: "Жен.", value: "female" },
];

const service = ref<IService>();
const createLoading = ref(false);
onMounted(async () => {
  service.value = (await getService("question")).value;
});
const newQuestion = ref({
  article: "",
  question: "",
  gender: "",
  publish_date: "",
});
function validateForm() {
  if (!newQuestion.value.question) {
    notify({
      text: "Напишите ваш вопрос",
    });
    return false;
  }
  if (newQuestion.value.publish_date) {
    const publishDate = new Date(newQuestion.value.publish_date);

    if (publishDate < currentDate) {
      notify({
        text: "Дата публикации не должна быть меньше текущей даты",
      });
      return false;
    }
  }

  return true;
}
const SendQuestion = async () => {
  const is_validate = validateForm();
  if (is_validate) {
    createLoading.value = true;
    const res = await QuestionService.createQuestion(newQuestion);
    if (res) {
      unobserve();
      await refresh();
      observe();
      createLoading.value = false;
    }
  }
};
const currentDate = new Date();
const twoWeeksFromNow = new Date(
  currentDate.getTime() + 14 * 24 * 60 * 60 * 1000
);

const minDate = ref(getFormattedDate(currentDate));
const maxDate = ref(getFormattedDate(twoWeeksFromNow));
const fetchData = (page: number, pageSize: number) =>
  QuestionService.getQuestions(page, pageSize);

// Используем useInfiniteScroll с fetchData
const { items, loadMore, refresh } = useInfiniteScroll(fetchData);
// Подключаем IntersectionObserver, передавая loadMore как callback
const {
  target: loadMoreTrigger,
  observe,
  unobserve,
} = useIntersectionObserver(loadMore, { rootMargin: "100px" });
</script>
