import axios from "@/axiosInstance";
import { ref } from "vue";
import { IQuestionProduct } from "../interfaces";
import store from "@/store";

class QuestionService {
  async createQuestion(newQuestion: any) {
    try {
      const response = await axios.post(
        "warming/post/questions/create",
        newQuestion.value
      );
      if (response.status == 200) {
        store.dispatch("refreshBalance");
      }
      return response.status == 200;
    } catch (e) {
      return false;
    }
  }

  async getQuestions(page: number, pageSize: number) {
    const questions = ref<Array<IQuestionProduct>>([]);
    try {
      const response = await axios.get(`warming/get/questions`, {
        params: { page, size: pageSize },
      });
      questions.value = response.data;
      return questions.value;
    } catch (e) {
      alert("Error");
    }
  }
}

export default new QuestionService();
