<template>
  <div class="dropdown w-100 w-md-auto" :class="{ show: isDropdownOpen }">
    <button
      class="btn btn-secondary dropdown-toggle w-100 w-md-auto"
      type="button"
      :id="dropdownId"
      :aria-expanded="isDropdownOpen ? 'true' : 'false'"
      @click="toggleDropdown"
    >
      {{ selectedOption.value }}
    </button>
    <ul
      class="dropdown-menu"
      :class="{ show: isDropdownOpen }"
      :aria-labelledby="dropdownId"
    >
      <li
        v-for="option in options"
        :key="option.key"
        @click="selectOption(option)"
      >
        <a class="dropdown-item" href="#">{{ option.value }}</a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps({
  options: {
    type: Array as () => Array<{ key: string; value: string }>,
    required: true,
  },
  defaultOption: {
    type: Object as () => { key: string; value: string },
    required: true,
  },
  dropdownId: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(["selectOption"]);

// Реактивные переменные для хранения текущего состояния dropdown
const selectedOption = ref(props.defaultOption);
const isDropdownOpen = ref(false);

// Функция для сворачивания/разворачивания dropdown
const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

// Функция для выбора опции из меню
const selectOption = (option: { key: string; value: string }) => {
  selectedOption.value = option;
  isDropdownOpen.value = false; // Закрываем меню после выбора
  emits("selectOption", option.key); // Возвращаем ключ вместо значения
};

// Закрываем меню при клике вне компонента
document.addEventListener("click", (event) => {
  const targetElement = event.target as HTMLElement;
  if (!targetElement.closest(`#${props.dropdownId}`)) {
    isDropdownOpen.value = false;
  }
});
</script>

<style scoped>
.w-100 {
  width: 100%;
}

.w-md-auto {
  width: auto !important;
}

/* Добавляем стили для показа/скрытия меню */
.show {
  display: block !important;
}

.dropdown-menu {
  display: none;
}
</style>
