<template>
  <LayuotPage>
    <div class="container-sm ps-7 p-0">
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-12">
          <h2 class="pt-3 pb-4 text-left font-bold font-up deep-purple-text">
            Доставки
          </h2>
          <MyBreadCrumbs :crumbs="crumbs"></MyBreadCrumbs>
        </div>
      </div>
      <!-- Блок фильтров и кнопки Excel -->
      <!-- Блок фильтров и кнопки Excel -->
      <div class="row g-3 align-items-center mb-2">
        <!-- Блок с фильтрами и ПВЗ -->
        <div class="col-12 col-md-8 d-flex flex-column flex-md-row">
          <!-- Dropdown для статуса -->
          <DropDownMenu
            :options="statusOptions"
            :defaultOption="{ key: 'active', value: 'Активные' }"
            dropdownId="dropdownMenuButton"
            @selectOption="onStatusChange"
            class="me-md-3 mb-3 mb-md-0"
          />

          <!-- Dropdown для выбора ПВЗ -->
          <DropDownMenu
            :options="pvzOptions"
            :defaultOption="{ key: 'any', value: 'ПВЗ: Любой' }"
            dropdownId="dropdownPvzButton"
            @selectOption="onPvzChange"
          />
        </div>

        <!-- Кнопка скачивания Excel справа -->
        <div class="col-12 col-md-4 text-md-end">
          <button
            class="btn btn-outline-primary w-50 w-md-auto"
            @click="downloadExcel"
          >
            Скачать Excel
          </button>
        </div>
      </div>
      <div class="row" v-if="!isLoading">
        <PurchaseList
          :carts="filteredCarts"
          :manage="manage"
          @open-status="ShowModal"
          @open-qr="getQR"
        ></PurchaseList>
      </div>
      <div class="row" v-else>
        <AccordionSkeleton></AccordionSkeleton>
      </div>
    </div>
    <ModalsContainer />
  </LayuotPage>
</template>
<script setup lang="ts">
import { computed, ref, watchEffect } from "vue";
import PurchaseList from "./components/PurchaseList.vue";
import LayuotPage from "../LayuotPage.vue";
import MyBreadCrumbs from "@/components/MyBreadCrumbs.vue";
import DropDownMenu from "@/components/UI/DropDownMenu.vue";
import RepaymentService from "./hooks/RepaymentService";
import StatusModal from "./modals/StatusModal.vue";
import AccordionSkeleton from "@/components/skeletons/AccordionSkeleton.vue";
import { ModalsContainer, useModal } from "vue-final-modal";
import { IRepaymentCart, TrackingStatus } from "./intefaces";
import QRModal from "./modals/QRModal.vue";
const crumbs = [
  {
    text: "Доставки",
    url: "/reviews",
  },
  {
    text: "Доставки",
  },
];

const manage = true;
const { isLoading, carts } = RepaymentService.getDelivery();

// Опции для фильтров по статусу
const statusOptions = [
  { key: "active", value: "Активные" },
  { key: "in_transit", value: "В пути" },
  { key: "ready_for_pickup", value: "Готовы к выдаче" },
  { key: "completed", value: "Завершенные" },
];

// ref для хранения опций ПВЗ
const pvzOptions = ref<Array<{ key: string; value: string }>>([
  { key: "any", value: "Любой" },
]);

// Отслеживаем изменения в carts и обновляем pvzOptions
watchEffect(() => {
  if (carts.value.length) {
    pvzOptions.value = Array.from(
      new Set(carts.value.map((cart) => cart.punkt.id_wb))
    ).map((id_wb) => {
      const punkt = carts.value.find(
        (cart) => cart.punkt.id_wb === id_wb
      )?.punkt;
      return {
        key: String(id_wb),
        value: punkt ? punkt.address : "Неизвестный ПВЗ",
      };
    });

    // Добавляем "Любой" как первый элемент
    pvzOptions.value.unshift({ key: "any", value: "Любой" });
  }
});

// Состояние для выбранных фильтров
const selectedStatus = ref("active");
const selectedPvz = ref("any");

// Фильтрация по статусу
const filterByStatus = (
  carts: Array<IRepaymentCart>,
  statusKey: string
): Array<IRepaymentCart> => {
  switch (statusKey) {
    case "active":
      return carts.filter((cart) => cart.status !== "RECIEVE");
    case "in_transit":
      return carts.filter((cart) => cart.status === "PAYMENT");
    case "ready_for_pickup":
      return carts.filter(
        (cart) => cart.status === "DELIVERY" && cart.qrcode !== undefined
      );
    case "completed":
      return carts.filter((cart) => cart.status === "RECIEVE");
    default:
      return carts;
  }
};

// Фильтрация по ПВЗ
const filterByPvz = (
  carts: Array<IRepaymentCart>,
  pvzKey: string
): Array<IRepaymentCart> => {
  if (pvzKey === "any") {
    return carts;
  } else {
    return carts.filter((cart) => String(cart.punkt.id_wb) === pvzKey);
  }
};

// Применяем обе фильтрации
const filteredCarts = computed(() => {
  return filterByPvz(
    filterByStatus(carts.value, selectedStatus.value),
    selectedPvz.value
  );
});

// Логика выбора статуса
const onStatusChange = (statusKey: string) => {
  selectedStatus.value = statusKey;
};

// Логика выбора ПВЗ
const onPvzChange = (pvzKey: string) => {
  selectedPvz.value = pvzKey;
};
const downloadExcel = async () => {
  await RepaymentService.exportDelivery();
};
const currentItemStatus = ref<Array<TrackingStatus> | undefined>();
const ShowModal = async (id: number) => {
  const items = (await RepaymentService.getItemStatus(id)).items;
  if (items) {
    currentItemStatus.value = items.value;
    open();
  }
};
const cartQr = ref("");
const getQR = async (cart: IRepaymentCart) => {
  const newCartQr = await RepaymentService.getQr(cart.id);
  if (newCartQr.value) {
    cartQr.value = newCartQr.value.qr_url;
    cart.qrcode = newCartQr.value;
    openQR();
  }
};
const { open, close } = useModal({
  component: StatusModal,
  attrs: {
    items: currentItemStatus,
    onCloseicon() {
      close();
    },
  },
});
const { open: openQR, close: closeQR } = useModal({
  component: QRModal,
  attrs: {
    qrcode: cartQr,
    onCloseicon() {
      closeQR();
    },
  },
});
</script>
