// hooks/useInfiniteScroll.ts
import { ref } from "vue";

export function useInfiniteScroll(
  fetchData: (page: number, pageSize: number) => Promise<any>,
  pageSize = 50
) {
  const items = ref<any[]>([]);
  const isLoading = ref(false);
  const currentPage = ref(1);
  const isEndOfList = ref(false);

  // Функция для загрузки данных
  const loadMore = async () => {
    if (isLoading.value || isEndOfList.value) return; // Останавливаем загрузку, если флаг isEndOfList установлен
    isLoading.value = true;
    try {
      const data = await fetchData(currentPage.value, pageSize);

      // Проверяем, достигли ли конца списка
      if (data.length < pageSize) {
        isEndOfList.value = true;
      } else {
        isEndOfList.value = false;
      }

      // Добавляем новые данные или перезаписываем, если это первая страница
      if (currentPage.value === 1) {
        items.value = data;
      } else {
        items.value.push(...data);
      }
      currentPage.value += 1; // Увеличиваем страницу для следующего вызова
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      isLoading.value = false;
    }
  };

  // Функция для сброса состояния и перезагрузки данных
  const refresh = async () => {
    items.value = []; // Очищаем список
    currentPage.value = 1; // Сбрасываем текущую страницу до 1
    isEndOfList.value = false; // Сбрасываем флаг конца списка
    await loadMore(); // Загружаем первую страницу данных
  };

  return { items, isLoading, loadMore, isEndOfList, refresh };
}
