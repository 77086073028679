<template>
  <div class="card my-3 col-3 mb-2 me-4 ml-2">
    <div class="card-header d-flex justify-content-between align-items-center">
      Группа № {{ order.id }}
      <div class="dropdown" @click="toggleDropdown">
        <IconButton :icon-class="'bx bx-dots-horizontal-rounded'" />
        <ul
          class="dropdown-menu"
          :class="{ show: isDropdownOpen }"
          aria-labelledby="dropdownMenuButton"
        >
          <li>
            <a
              class="dropdown-item"
              href="#"
              @click="Action(order.id, 'archive')"
            >
              <template v-if="!order.is_archive"> В архив </template>
              <template v-else> Из архива </template>
            </a>
          </li>
          <!-- <li>
            <a
              class="dropdown-item"
              href="#"
              @click="Action(order.id, 'dublicate')"
              >Дублировать</a
            >
          </li> -->
        </ul>
      </div>
    </div>
    <div class="card-body">
      <h5 class="card-title p-0 bc-white">
        <StatusSpan :color-class="statusClassStatus(order.status).class">{{
          statusClassStatus(order.status).text
        }}</StatusSpan>
      </h5>
      <p class="card-text">
        <strong>Дата создания:</strong> {{ order.created }}<br />
        <strong>Оплачено:</strong> {{ order.payment_cost }}₽ /
        {{ order.total_cost }}₽<br />
        <strong>Завершено заказов:</strong>
        {{ order.payment_carts }} шт. / {{ order.count_carts }} шт.
      </p>
      <swiper
        :modules="[Virtual]"
        :slides-per-view="2"
        :space-between="10"
        virtual
      >
        <!-- Slides -->
        <swiper-slide
          class="swiper-slide"
          v-for="(product, index) in order.products"
          :key="product.id"
          :virtualIndex="index"
          style="width: 18rem"
        >
          <ProductCardSmall
            :photo_url="product.photo_url"
            :article="product.article.toString()"
            :name="product.name"
          />
        </swiper-slide>
      </swiper>
      <!-- If we need pagination -->
      <div class="swiper-pagination"></div>
      <!-- If we need navigation buttons -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>
    </div>
    <MyButton @click="$router.push(`/repayment/${order.id}`)">
      Открыть
    </MyButton>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { defineProps, defineEmits } from "vue";
import { IRepaymentOrder } from "../intefaces";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Virtual } from "swiper/modules";
import MyButton from "@/components/UI/MyButton.vue";
import ProductCardSmall from "@/components/ProductCardSmall.vue";
import IconButton from "@/components/UI/IconButton.vue";
import StatusSpan from "@/components/UI/StatusSpan.vue";
import { statusClassStatus } from "@/hooks/";

defineProps<{
  order: IRepaymentOrder;
}>();
const emit = defineEmits(["action"]);

const isDropdownOpen = ref(false);

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value;
};

const Action = (id: number, action: string) => {
  emit("action", id, action);
  isDropdownOpen.value = true;
};
</script>

<style scoped>
.card-header {
  background-color: white;
}
</style>
