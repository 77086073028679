<template>
  <td class="col-2 col-sm-2">
    <div class="col-6">
      <ImageContainer :photo_url="review.product.photo_url"></ImageContainer>
    </div>
  </td>
  <td class="col-2 col-sm-1">{{ review.product.article }}</td>
  <td class="col-5 col-sm-5">
    {{ review.fake_user.name }}
    <br />
    <i v-for="index in review.rating" :key="index" class="star">&#9733;</i>
    <hr />
    <transition name="text-transition">
      <p class="" v-if="isFullText">
        <template v-if="review.advantages">
          <b> Достоинства: </b><br />
          {{ review.advantages }}
          <br />
        </template>
        <template v-if="review.disadvantages">
          <b> Недостатки:</b><br />
          {{ review.disadvantages }}
          <br />
        </template>
        <template v-if="review.review">
          <b> Комментарий: </b> <br />
          {{ review.review }}
        </template>
      </p>
      <p class="" v-else>
        <template v-if="review.advantages">
          <b> Достоинства </b><br />
          {{ review.advantages.slice(0, 20) }}
          <br />
        </template>
        <template v-if="review.disadvantages">
          <b> Недостатки</b><br />
          {{ review.disadvantages.slice(0, 20) }}
          <br />
        </template>
        <template v-if="review.review">
          <b> Комментарий к отзыву </b> <br />
          {{ review.review.slice(0, 20) }}
        </template>
      </p>
    </transition>
    <button
      @click="toggleText"
      class="btn btn-sm btn-link mt-0 p-0"
      v-if="
        review.review?.length > 50 ||
        review.advantages?.length > 50 ||
        review.disadvantages?.length > 50 ||
        (review.review?.length || 0) +
          (review.advantages?.length || 0) +
          (review.disadvantages?.length || 0) >
          50
      "
    >
      {{ isFullText ? "Свернуть" : "Ещё..." }}
    </button>
  </td>
  <td class="col-3 col-sm-3">
    <div class="row">
      <div
        class="col-5 col-sm-5 me-1 mb-1"
        v-for="(photo, index) in review.photos"
        :key="index"
      >
        <ImageContainer :photo_url="base_url + photo"></ImageContainer>
      </div>
    </div>
  </td>
  <td class="col-2 col-sm-3">{{ review.publish_date }}</td>
  <td class="col-2 col-sm-3">
    <StatusSpan :color-class="statusClassStatus(review.status).class">{{
      statusClassStatus(review.status).text
    }}</StatusSpan>
  </td>
</template>
<script setup lang="ts">
import ImageContainer from "@/components/UI/ImageContainer.vue";
import StatusSpan from "@/components/UI/StatusSpan.vue";
import { statusClassStatus } from "@/hooks/";
import { defineProps, ref } from "vue";
import { IReview } from "../intefaces";

const base_url = ref(process.env.VUE_APP_SERVER);
const props = defineProps<{
  review: IReview;
}>();
const review = ref(props.review);
const isFullText = ref(false);

const toggleText = () => {
  isFullText.value = !isFullText.value;
};
</script>
